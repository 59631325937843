.blogs {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .blog-list {
    list-style-type: none;
    padding: 0;
    max-width: 600px;
    margin: 0 auto; /* Center the list */
  }
  
  .blog-item {
    border: 1px solid #ddd;
    border-radius: 10px;
    margin-bottom: 15px;
    padding: 15px;
    background-color: #f9f9f9;
    transition: box-shadow 0.3s ease;
  }
  
  .blog-item:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .blog-title {
    font-size: 1.5rem;
    text-decoration: none;
    color: #007bff;
    font-weight: bold;
  }
  
  .blog-title:hover {
    text-decoration: underline;
  }
  
  .blog-description {
    font-size: 1rem;
    color: #555;
    margin-top: 10px;
  }
  