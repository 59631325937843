.services {
    text-align: center;
    padding: 20px;
  }
  
  .services-container {
    display: flex;
    justify-content: space-around; /* Space between the boxes */
    align-items: center;
    gap: 20px; /* Space between boxes */
    margin-top: 20px;
  }
  
  .service-box {
    background-color: #f4f4f4; /* Light background */
    border: 2px solid #ddd; /* Border color */
    border-radius: 15px; /* Rounded corners */
    padding: 20px; /* Padding inside the box */
    width: 20vw; /* Box width */
    height: 20vh; /* Box height */
    display: flex;
    justify-content: center; /* Center text horizontally */
    align-items: center; /* Center text vertically */
    font-size: 1.2rem; /* Font size */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    transition: transform 0.2s, box-shadow 0.2s; /* Smooth hover effect */
  }
  
  .service-box:hover {
    transform: translateY(-5px); /* Slight lift on hover */
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15); /* Enhanced shadow */
  }
  