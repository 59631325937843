/* Sidebar container */
.sidebar {
    height: 100vh; /* Full viewport height */
    width: 150px; /* Sidebar width */
    position: fixed; /* Stays fixed on the left */
    top: 0;
    left: 0;
    background-color: #f4f4f4; /* Light background */
    color: black; /* Dark text */
    padding: 20px; /* Inner spacing */
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
    font-family: Arial, sans-serif;
  }

/* Sidebar title */
.sidebar h2 {
font-size: 1.5rem;
margin-bottom: 20px;
color: #333; /* Neutral text color */
}

/* List styling */
.sidebar ul {
list-style: none;
padding: 0; /* Remove default padding */
margin: 0; /* Remove default margin */
}

/* List items */
.sidebar ul li {
margin: 15px 0;
}

/* Links */
.sidebar ul li a {
text-decoration: none;
color: #333; /* Neutral color */
font-size: 1rem;
padding: 5px 10px;
display: block; /* Makes the link clickable over its entire area */
border-radius: 5px;
}

.sidebar ul li a:hover {
background-color: #ddd; /* Light gray hover effect */
color: #000; /* Black text */
}

/* Responsive sidebar */
@media (max-width: 768px) {
.sidebar {
    width: 100px; /* Narrower sidebar for smaller screens */
}

.sidebar h2 {
    font-size: 1rem;
}

.sidebar ul li a {
    font-size: 0.9rem;
}
}

@media (max-width: 480px) {
.sidebar {
    width: 120px; /* Even narrower for phones */
}

.sidebar ul li a {
    font-size: 0.8rem;
}
}


/* Remove default styles from links */
a {
text-decoration: none; /* Removes underline */
color: inherit; /* Inherit the color from the parent */
}


.sidebar ul {
list-style-type: none;
padding: 0;
}

.sidebar ul li {
margin: 15px 0;
}

.sidebar ul li a {
color: black; /* Set color for links */
font-size: 1rem;
padding: 5px 10px;
display: block;
border-radius: 5px;
}

.sidebar ul li a:hover {
background-color: #ddd; /* Light hover effect */
color: #000;
}
